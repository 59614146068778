<template>

  <div class="main-wrapper">
    <layouts></layouts>
    <router-view v-slot='{ Component }'>
      <div class="breadcrumb-bar p-0">
        <div class="container p-3">
          <div class="breadcrumb-list">
            <nav aria-label="breadcrumb" class="page-breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item" v-for="(item, i) in route" :key="i">
                  <router-link :to="item.path" v-if="item.path">{{ item.name }}</router-link>
                  <router-link class="text-muted" to="" v-else>{{ item.name }}</router-link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <nav class="navbar navbar-light alert-warning text-black" v-if="checkModoAluno()">
        <div class="container-fluid">
          <div class="container">
            Modo aluno visualizando como: <b>{{ getNomeModoAluno() }}</b>
            <a class="link-danger pull-end" href="javascript:void(0)" @click="sairModoAluno">Sair do modo aluno</a>
          </div>
        </div>
      </nav>
      <div class="page-content">
        <div class="container">
          <div class="row" v-if="showSideBar">
            <div class="col-xl-3 col-lg-4 col-md-12 theiaStickySidebar">
              <sidebar></sidebar>
            </div>
            <div class="col-xl-9 col-lg-8 col-md-12">
              <component :is='Component'/>
            </div>
          </div>
          <component v-else :is='Component'/>
        </div>
      </div>
    </router-view>
    <layouts1></layouts1>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import ModoAluno from "../helpers/helper.modo.aluno"

export default {
  name: "DefaultLayout",
  mixins: [ModoAluno],
  data() {
    return {
      route: [],
      fullPath: '',
      menuList: [
        // '/academico',
        // '/administrativo'
      ]
    }
  },
  computed: {
    showSideBar() {
      if (this.$route.matched[0].path == '/pages')
        return this.menuList.includes(this.$route.matched[1].path)
      return false
    },
  },
  mounted() {
    const route = useRoute()
    this.breadcrumb(route)
  },
  methods: {
    breadcrumb(route) {
      this.fullPath = route.fullPath;
      let path = []
      route.matched.forEach(item => {

        if (item.name != '' && !path.map(i => i.name).includes(item.name))

          if (['/inicio', '/aluno/sala-de-aula'].includes(item.path.toLowerCase()) ||
              item.path == `/${this.fullPath.split('/')[1]}`)
            path.push({
              path: null,
              name: item.name
            });

          else
            path.push({
              path: item.path,
              name: item.name
            });
      })
      this.route = path;
    },
  },
  watch: {
    $route(to, from) {
      this.breadcrumb(to);
    }
  }
}
</script>

<style scoped>

</style>
