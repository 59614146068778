import {createApp} from 'vue';
import App from "./App.vue";
import {router} from './router';
import Vue3Autocounter from 'vue3-autocounter';
import 'regenerator-runtime';

/*********Home**********/
import homebanner from './views/pages/home/homebanner.vue'
import featurecourse from './views/pages/home/featurecourse.vue'
import trendingcourse from './views/pages/home/trendingcourse.vue'
import shareknowledge from './views/pages/home/shareknowledge.vue'
import latestblog from './views/pages/home/latestblog.vue'
import dashboardindex from './views/pages/home/dashboardindex.vue'
import featureinstructors from './views/pages/home/featureinstructors.vue'
import leadingcompanies from './views/pages/home/leadingcompanies.vue'

/*********Header component**********/
import Header from './views/layouts/header.vue'
import IndexHeader from './views/layouts/indexheader.vue'
import HeaderBorder from './views/layouts/headerborder.vue'
import HeaderPage from './components/headerpage.vue'
import Menuheader from './components/menuheader.vue'
import Mainnav from './components/mainnav.vue'
import DashboardMenu from './components/dashboardmenu.vue'
import Signpages from './components/signpages.vue'
import Navbar from './components/navbar.vue'
import LoginHeader from './views/layouts/loginheader.vue'
import Sidebar from './views/layouts/sidebar.vue'
// import LoginHeaderBorder from './views/layouts/loginheaderborder.vue'
// import Instructorheader from './views/layouts/instructorheader.vue'
// import Instructorheaderborder from './views/layouts/instructorheaderborder.vue'

/*********Footer component**********/
import Footer from './views/layouts/footer.vue'
import FooterTop from './components/footertop.vue'
import FooterBottom from './components/footerbottom.vue'

/*********Pages**********/
import LoginBanner from './components/loginbanner'


/*******Plugin import***********/
import jquery from 'jquery';

window.$ = jquery


/*******Third party Plugins***********/
import VueSelect from 'vue3-select2-component'
import SummernoteEditor from 'vue3-summernote-editor';
import VueFeather from 'vue-feather';
// import VueApexCharts from "vue3-apexcharts";
// import MaskPlugin from './plugins/mask.js';
import {vMaska} from "maska"

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Swal from "sweetalert2/dist/sweetalert2.js";


/*******Bitzar Plugins***********/
import DataTable from "./components/Bitzar/DataTable";
import Pagination from "./components/Bitzar/Pagination";
import DefaultPage from "./components/Bitzar/DefaultPage";
import MultselectForm from "./components/Bitzar/MultselectForm";
import DatePicker from "./components/Bitzar/DatePicker";
import Gallery from "./components/Bitzar/Gallery";


import './assets/js/jquery-3.6.0.min.js'
import './assets/css/bootstrap.min.css';
import './assets/js/bootstrap.bundle.min.js';
import './assets/css/feather.css';
import './assets/plugins/fontawesome/css/fontawesome.min.css';
import './assets/plugins/fontawesome/css/all.min.css';


import './assets/plugins/summernote/dist/summernote-lite.min.css';
import './assets/plugins/summernote/dist/summernote-lite.min.js';

import './assets/plugins/slick/slick.css';
import './assets/plugins/slick/slick-theme.css';
import './assets/plugins/slick/slick.js';
import './assets/plugins/dropzone/dropzone.min.js';
import './assets/plugins/dropzone/dropzone.min.css';
import './assets/plugins/aos/aos.css';
import './assets/plugins/aos/aos.js';
import './assets/css/style.css';
import './assets/css/default.layout.css';

/*******Capacitor***********/
import {SplashScreen} from '@capacitor/splash-screen';
import {App as CapacitorApp} from '@capacitor/app';
import {Capacitor} from "@capacitor/core";


if (window?.currentTheme == 'esn') {
  import ('../src/assets/css/esn-mode.css')
} else {
  import ('../src/assets/css/uca-mode.css')
}

// configura as rotas sem autenticação
window.rotasSemAuth = ['/', '/login', '/alterar-senha', '/recuperar-senha', '/privacidade', '/termouso']


// Hide the splash (you should do this on app launch)
await SplashScreen.hide();

// Show the splash for an indefinite amount of time:
await SplashScreen.show({
  autoHide: false,
});

// Show the splash for two seconds and then automatically hide it:
await SplashScreen.show({
  showDuration: 2000,
  autoHide: true,
}).then(async () => {

  setInterval(async () => {
    await SplashScreen.hide();
  }, 2000)

});

// se for IOS adiciona uma safe area para o sistema nao fica em baixo do notch
if (Capacitor.getPlatform() === 'ios') {
  document.body.classList.add('safe-area')
}


// mobile - guarda a rota de navegação desejada para voltar
window.backNavigation = null

CapacitorApp.addListener('backButton', ({canGoBack}) => {

  // se um modal esta aberto
  const isModalOpen = !!document.querySelector('.modal.show')
  if (isModalOpen)
    return

  if (window.backNavigation) {
    router.push(window.backNavigation)
    return
  }

  if (canGoBack) {
    // Voltar para a página anterior no histórico do Vue Router
    window.history.back();

  } else {
    // Mostrar um alerta ou sair do aplicativo
    if (confirm('Deseja sair do aplicativo?')) {
      CapacitorApp.exitApp();
    }
  }
});


window.SUMMERNOTE_DEFAULT_CONFIGS = {
  toolbar: [],
}

const SwalOptions = {
  allowOutsideClick: false,
  confirmButtonColor: '#258F71',
  cancelButtonColor: '#ae2828'
}

const swalAlert = Swal.mixin(
{
  ...SwalOptions,
  didOpen: () => {
    Swal.hideLoading();
  },
}
);
window.swalAlert = swalAlert;


const swalToast = Swal.mixin({
  toast: true,
  position: "top-end",
  timer: 7000,
  timerProgressBar: true,
  showConfirmButton: false,
  showCloseButton: true,
  didOpen: () => {
    Swal.hideLoading();
  }
});
window.swalToast = swalToast;

const swalLoading = Swal.mixin({
  allowOutsideClick: false,
  title: '',
  html: 'Carregando...',
  timerProgressBar: true,
  didOpen: () => {
    Swal.showLoading()
  },
});
window.swalLoading = swalLoading;


window.addEventListener("offline", (event) => {
  window.swalLoading.fire('Offline', 'Verifique a sua conexão com a internet', 'warning')
  console.info('[System] Offline');
});

window.addEventListener("online", (event) => {
  window.swalToast.close()
  console.info('[System] Online');
});


const app = createApp(App)

/*********Home**********/
app.component('homebanner', homebanner)
app.component('featurecourse', featurecourse)
app.component('trendingcourse', trendingcourse)
app.component('shareknowledge', shareknowledge)
app.component('latestblog', latestblog)
app.component('dashboardindex', dashboardindex)
app.component('leadingcompanies', leadingcompanies)
app.component('featureinstructors', featureinstructors)

/*********Header component**********/
app.component('layouts', Header)
app.component('layoutsindex', IndexHeader)
app.component('layouts2', HeaderBorder)
app.component('headerpage', HeaderPage)
app.component('menuheader', Menuheader)
app.component('mainnav', Mainnav)
app.component('dashboardmenu', DashboardMenu)
app.component('signpages', Signpages)
app.component('navbar', Navbar)
app.component('layoutslogin', LoginHeader)
app.component('sidebar', Sidebar)

// app.component('layoutsloginborder', LoginHeaderBorder)
// app.component('layoutsinstructor', Instructorheader)
// app.component('layoutsinstructorborder', Instructorheaderborder)

/*********Footer component**********/
app.component('layouts1', Footer)
app.component('footertop', FooterTop)
app.component('footerbottom', FooterBottom)

/*********Pages**********/
app.component('loginbanner', LoginBanner)

/*******Third party Plugins***********/
app.component('SummernoteEditor', SummernoteEditor)
app.component('vue3-autocounter', Vue3Autocounter)

app.component('vue-select', VueSelect)
app.component(VueFeather.name, VueFeather)
app.directive("maska", vMaska)

/*******Bitzar Plugins***********/
app.component('DataTable', DataTable)
app.component('Pagination', Pagination)
app.component('DefaultPage', DefaultPage)
app.component('MultselectForm', MultselectForm)
app.component('DatePicker', DatePicker)
app.component('Gallery', Gallery)


//app.use(VueApexCharts);
app.use(
VueSweetalert2,
SwalOptions
)

app.use(router)
.mount('#app');
